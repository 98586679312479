import React from 'react';
import axios from 'axios';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Link, injectIntl } from "gatsby-plugin-react-intl"
import { message } from 'antd';
import {
  Form, Input, Tooltip, Icon, Select, Checkbox, Button, Alert
} from 'antd';
import Loader from './Loader.gif'
import { TRIAL_SUBMIT_URL, CAPTCHA_SITE_KEY } from '../../data/data';

const { Option } = Select;

const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class DemoFormDraft extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    checked: false, 
    showAlert: 'none',
    loading: false,
    hCaptchaToken: ''
  };

  handleSubmit = (values) => {
    if (!this.state.hCaptchaToken) {
      message.error('Please verify captcha');
      return;
    }

    if (!this.state.checked) {
      this.setState({ showAlert: '' });
    } else {
      this.setState({ showAlert: 'none', loading: true });
      const language = navigator.language || navigator.userLanguage;

      axios.post(TRIAL_SUBMIT_URL, {
        captchaToken: this.state.hCaptchaToken,
        language,
        ...values
      }).then((response) => {
        console.log({ response });
        this.setState({ loading: false });
        response.data.success ?
          message.success('Please Check your email') : message.error(response.data.msg);
      }).catch((error) => {
        console.log({ error });
      });
    }
  };

  handleCheckBoxChange = (e) => {
    this.setState({ checked: !this.state.checked });
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;


    return (
      <>
      { this.state.loading ?
        <div style={{ position: 'fixed', top: '0', left: '0',width: '100%', height: '100%', backgroundColor: '#F0F0F0', opacity: '0.7', zIndex: '1' }}>
          <div style={{ zIndex: '10', opacity: '0.85', margin: 'auto', display: 'block', position: "absolute", top: "50%", textAlign: 'center', left: "50%", transform: 'translate(-50%, -50%)' }}>
            <img src={Loader} style={{ width: '200px' }} alt='Loading...' />
            <h3 style={{ color: '#696969' }}>Processing...</h3>
          </div>
        </div> : null
      }
      <Form
        {...FORM_ITEM_LAYOUT}
        onFinish={this.handleSubmit}
      >
        <Form.Item
          label={<span>{intl.formatMessage({ id: "name" })}</span>}
          name='name' rules={[{ required: true, message: 'Please input your name!', whitespace: true }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "companyEmail" })}
          name='email'
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Please input your E-mail!' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <span>
              {intl.formatMessage({ id: "company" })}&nbsp;
              <Tooltip title="Please fill in the company name so we can know more about you">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
          name="company"
          rules={[{ required: true, message: 'Please input your company name!', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "phone" })}
          name="phone"
          rules={[{ required: true, message: 'Please input your phone number!' }]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={<span>{intl.formatMessage({ id: "country" })}</span>}
          name="country"
          rules={[{ required: true, message: 'Please input country!', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: "product" })}
          name="version"
          rules={[{ required: true, message: 'Please select the product!' }]}
          initialValue='wug'
        >
          <Select>
            <Option value="wug">{intl.formatMessage({ id: 'title' })} for Whatsup Gold</Option>
            <Option value="netapp">{intl.formatMessage({ id: 'title' })} for NetApp</Option>
            <Option value="paloalto">{intl.formatMessage({ id: 'title' })} for PaloAlto</Option>
          </Select>
        </Form.Item>
        <Form.Item
          className="checkbox" style={{width:'100%'}}
          name="agreement"
          valuePropName="checked"
          rules={[{ required: true, message: 'Please agree to the data collection agreement!' }]}
        >
          <Checkbox onChange={this.handleCheckBoxChange}>
            {intl.formatMessage({ id: "agree" })}<Link to="/privacy">{intl.formatMessage({ id: "personal-data" })}</Link>
          </Checkbox>
        </Form.Item>
        <Alert
          message="We Protect Your Data"
          description="Please agree to the Personal Data Collection Agreement."
          type="warning"
          showIcon
          style={{ display: this.state.showAlert }}
        />
        <HCaptcha
          sitekey={CAPTCHA_SITE_KEY}
          onVerify={(hCaptchaToken) => {
            this.setState({ hCaptchaToken });
          }}
          onExpire={() => {
            this.setState({ hCaptchaToken: '' });
          }}
        />
        <div style={{textAlign:'center'}}>
          <Button type="primary" htmlType="submit">
          {intl.formatMessage({ id: "download" })}
          </Button>
        </div>
      </Form>
      </>
    );
  }
}

// const DemoForm = Form.create({ name: 'register' })(DemoFormDraft);

export default injectIntl(DemoFormDraft)
